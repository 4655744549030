import React, { useState, useEffect } from "react"
import styled from "styled-components"
import get from "lodash/get"
import RadarChart from "./RadarChart"
import ResponsiveChart from "../components/ResponsiveChart"
import RadarChartPopup from "./RadarChartPopup"
import TrackVisibility from "react-on-screen"

import useOverview from "../hooks/use-overview"

const VALUE_MAP = {
  0: "No Answer",
  1: "Never used",
  2: "Familiar with/use rarely",
  3: "Use sometimes",
  4: "Regularly use",
  5: "Use Often"
}

const AVERAGE_MAP = {
  0: "Use rarely",
  2: "Use sometimes",
  3.21: "Use sometimes to frequently",
  3.81: "Use frequently"
}

const getTooltipContent = (popup) => {
  const { dataKey } = popup
  const isAverage = dataKey === "average"
  const value = get(popup, 'payload.value')
  const cityCount = get(popup, 'payload.payload.cityCount')
  let title = VALUE_MAP[value]
  if(isAverage) {
    title = AVERAGE_MAP[0]
    Object.keys(AVERAGE_MAP).forEach(cutoff => {
      if(value > cutoff) {
        title = AVERAGE_MAP[cutoff]
      }
    })
  }
  return (
    <>
      <div className="title">
        {title}
      </div>
      {isAverage && cityCount && (
        <div className="text subtitle">
          Average of {cityCount} cities
        </div>
      )}
      <div className="text">
        {get(popup, 'payload.payload.category')}
      </div>
    </>
  )
}

const ActivityRadarChart = (props) => {
  const { cityName } = props
  const [active, updateActive] = useState(null)
  const [popup, updatePopup] = useState(null)
  const [containerRef, updateContainerRef] = useState(null)
  const overviewData = useOverview()
  const data = get(overviewData, 'activities.categories') || []

  useEffect(() => {
    const hideOnScroll = () => updatePopup(null)
    window.addEventListener("scroll", hideOnScroll)
    return () => {
      window.removeEventListener("scroll", hideOnScroll, false)
    }
  }, [updatePopup])
  return (
    <Wrapper>
      <div className="chart" ref={ref => updateContainerRef(ref)}>
        <ResponsiveChart
          initialWidth={480}
          initialHeight={480}
          delay={100}
          mobileAspectRatio={1}
        >
          {({ width, height }) => (
            <TrackVisibility once partialVisibility>
              {({ isVisible }) => isVisible && (
                <>
                  <RadarChart
                    {...props}
                    data={data}
                    width={width}
                    height={height}
                    handleMouseEnter={data => updatePopup(data)}
                    handleMouseLeave={() => updatePopup(null)}
                    activeId={get(active, "id")}
                  />
                  {popup && (
                    <RadarChartPopup
                      className="popup"
                      top={get(popup, 'cy')}
                      left={get(popup, 'cx')}
                      containerRef={containerRef}
                    >
                      <div className="content">
                        {getTooltipContent(popup)}
                      </div>
                      <div className="arrow"></div>
                    </RadarChartPopup>
                  )}
                  {cityName && (
                    <div className="legend">
                      <div className="legend__item city">{cityName}</div>
                      <div className="legend__item average">Average of all cities</div>
                    </div>
                  )}
                </>
              )}
            </TrackVisibility>
          )}
        </ResponsiveChart>
      </div>
      <div className="activity-legend">
        <ol className="activity-legend-list">
          {data.map((item, i) => (
            <li 
              className="activity-legend-list__item"
              key={`radar-chart-legend-${i}`}
              onMouseOver={() => updateActive(item)}
              onMouseLeave={() => updateActive(null)}
            >
              <span>{i + 1}</span>
              <p>{item.category}</p>
            </li>
          ))}
        </ol>
      </div>
      
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    flex-direction: row;
  }

  .chart {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${({ theme }) => theme.tablet}) {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }

  .activity-legend {

    @media (min-width: ${({ theme }) => theme.tablet}) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 25%;
      max-width: 25%;
      padding-left: 0;
    }

    .activity-legend-list {
      text-align: left;
      margin: 0;

      p, span {
        font-family: ${({ theme }) => theme.fonts['roboto']};
        font-size: 0.75rem;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: 0.3px;
        color: ${({ theme }) => theme.grey1 };
        margin: 0;
      }

      &__item {
        display: block;
        min-height: 1.5rem;
        position: relative;
        margin-bottom: 0.75rem;
        padding-top: 0.25rem;
        padding-left: 2.25rem;
        cursor: pointer;

        span {
          display: block;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 0;
          left: 0;
          border: solid 2px rgba(217, 226, 231, 0.3);
          border-radius: 12px;
          line-height: 20px;
          vertical-align: middle;
          text-align: center;
        }

        &:hover {
          p {
            color: black;
          }
        }
      }
    }
  }

  .legend {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    pointer-events: none;
    margin-bottom: 1rem;
    
    @media (min-width: ${({ theme }) => theme.mobile}) {
      margin-bottom: 1.5rem;
    }

    @media (min-width: ${({ theme }) => theme.tablet}) {
      margin-bottom: 1rem;
    }
    
    &__item {
      position: relative;
      display: inline-block;
      font-size: 0.75rem;
      font-weight: bold;
      font-family: ${({ theme }) => theme.fonts["roboto"]};
      color: ${({ theme }) => theme.grey1};
      line-height: 1.33;
      letter-spacing: 0.3px;
      padding-left: 1.5rem;
      margin-left: 2rem;

      &:first-child {
        margin: 0;
      }

      &:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        background: rgba(99, 140, 180, 0.5);
      }

      &.city {
        &:before {
          background: rgba(1, 68, 133, 0.85);
        }
      }
    }
  }

  .overview-block & {

    .chart {
      @media (min-width: ${({ theme }) => theme.tablet}) {
        flex: 0 0 65%;
        max-width: 65%;
      }
    }

    .activity-legend {
      display: block;

      @media (min-width: ${({ theme }) => theme.tablet}) {
        flex: 0 0 35%;
        max-width: 35%;
        padding-left: 1rem;
      }

      @media (min-width: ${({ theme }) => theme.desktop}) {
        padding-right: 3.5rem;
      }

      .activity-legend-list {

        p, span {
          color: white;
        }

        @media (min-width: ${({ theme }) => theme.largeDesktop}) {
          margin-top: 4rem;
        }
      }
    }
  }
`

export default ActivityRadarChart
