import React, { createRef, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import result from "lodash/result"
import get from "lodash/get"

const Popup = styled.div`
  display: block;
  width: 80%;
  max-width: 80vw;
  position: absolute;
  background: white;
  border-radius: 12px;
  pointer-events: none;
  transform: translate(-50%, calc(-100% - 0.625rem));
  text-align: left;
  padding: 1.25rem;
  box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.3);
  z-index: 2000;


  ${({ top = 0, left = 0, offset }) => css`
    top: ${top}px;
    left: ${left + offset}px;
  `}

  .title {
    display: block;
    font-family: ${({ theme }) => theme.fonts['roboto-condensed']};
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.34px;
    color: ${({ theme }) => theme.darkBlue} !important;
    white-space: nowrap;
    margin-bottom: 0.625rem;
  }

  .text {
    display: inline-block;
    font-family: ${({ theme }) => theme.fonts['vollkorn']};
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: -0.09px;
    color: ${({ theme }) => theme.grey1} !important;

    &.subtitle {
      display: block;
      font-family: ${({ theme }) => theme.fonts['roboto']};
      font-size: 0.75rem;
      color: ${({ theme }) => theme.grey1} !important;
      margin-bottom: 0.25rem;
    }
  }

  .arrow{
    position: absolute;
    top: calc(100% - 2px);
    left: calc(50% - 0.5rem);
    width: 0;
    height: 0;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.5rem solid #FFF;
    box-shadow: 0 2px 20px 0 rgba(98, 101, 110, 0.3);
    transform: translate(${({offset}) => -offset}px, 0);
  }
`

export default ({ children, ...props }) => {
  const popupRef = createRef()
  const [offset, updateOffset] = useState(0)
  useEffect(() => {
    const windowWidth = typeof(window) !== "undefined" ? get(window, "innerWidth") : null
    const bounds = result(popupRef.current, "getBoundingClientRect")
    if(bounds) {
      const newLeft = props.left - bounds.width / 2
      if (newLeft < 0) {
        updateOffset(Math.abs(newLeft))
        return
      }
      if(windowWidth) {
        if (newLeft + bounds.width > windowWidth) {
          updateOffset(windowWidth - (newLeft + bounds.width + 16))
          return
        }
      }
      updateOffset(0)
    }
  }, [popupRef, props])
  return (
    <Popup {...props} ref={popupRef} offset={offset}>
      {children}
    </Popup>
  )
}
