import { graphql, useStaticQuery } from "gatsby"
import get from "lodash/get"

const usePercentages = () => {
  const data = useStaticQuery(graphql`
    query {
      percentages: markdownRemark(frontmatter: { slug: { eq: "percentages"}}){
        frontmatter {
					explicit_strategy {
            has
            does_not_have
          }
          approach {
            holistic
            specific
            both
          }
          dedicated_funds {
            has
            does_not_have
          }
          top_skills {
            num_cities
            percent
            title
            total_num_cities
          }
        }
      }
    }
  `)

  return get(data, "percentages.frontmatter") || {}
}

export default usePercentages
