import React from "react"
import styled from "styled-components"
import get from "lodash/get"
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarRadiusAxis,
  PolarAngleAxis,
  Dot
} from "recharts"
import theme from "../styles/theme"

export default ({
  width = 500,
  height = 500,
  outerRadius = "90%",
  mode = "light",
  data = [],
  cityId = null,
  handleMouseEnter = () => {},
  handleMouseLeave = () => {},
  activeId,
  showAverageTooltips = true
}) => {
  return (
    <Wrapper>
      <RadarChart
        width={width}
        height={height}
        data={data}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        outerRadius={outerRadius}
      >
        <PolarGrid
          strokeOpacity={mode === "dark" ? 1 : 0.3}
          stroke={"#dddee0"}
        />
        <PolarRadiusAxis
          tickCount={6}
          stroke="transparent"
          domain={[0, 5]}
        />
        <PolarAngleAxis
          dataKey="subject"
          stroke={mode === "dark" ? theme.grey1 : "#FFF"}
          fontFamily="'Roboto'"
          fontSize="12px"
          fontWeight="bold"
          strokeWidth={mode === "dark" ? 1 : 4}
          strokeOpacity={0.3}
        />

        <Radar
          name="Average"
          dataKey="average"
          strokeWidth={2}
          stroke={mode === "dark" ? "rgba(99, 140, 180, 0)" : "rgba(255, 255, 255, 0.8)"}
          fill={mode === "dark" ? "rgba(99, 140, 180, 0.5)" : "rgba(255, 255, 255, 0.85)"}
          dot={!showAverageTooltips ? null : (props) => {
            const itemId = get(props, "payload.payload.id")
            const isActive = activeId && activeId === itemId
            return (
              <g key={props.key}>
                <Dot
                  {...props}
                  key={`${props.key}-a`}
                  fill="#FFFFFF"
                  stroke="transparent"
                  r={isActive ? 4.5 : 3}
                />
                <Dot
                  {...props}
                  key={`${props.key}-b`}
                  fill="transparent"
                  stroke="transparent"
                  cursor="pointer"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onMouseDown={handleMouseEnter}
                  r={15}
                />
              </g>
            )
          }}
        />
        {cityId && (
          <Radar
            name="City"
            dataKey={cityId}
            strokeWidth={2}
            stroke={mode === "dark" ? "rgba(1, 68, 133, 0.8)" : "transparent"}
            fill={mode === "dark" ? "rgba(1, 68, 133, 0.85)" : "#dddee0"}
            fillOpacity={mode === "dark" ? 1 : 0.5}
            dot={(props) => {
              const itemId = get(props, "payload.payload.id")
              const isActive = activeId && activeId === itemId
              return (
                <g key={props.key}>
                  <Dot
                    {...props}
                    key={`${props.key}-a`}
                    fill="#014485"
                    stroke="transparent"
                    r={isActive ? 4.5 : 3}
                  />
                  <Dot
                    {...props}
                    key={`${props.key}-b`}
                    fill="transparent"
                    stroke="transparent"
                    cursor="pointer"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onMouseDown={handleMouseEnter}
                    r={15}
                  />
                </g>
              )
            }}
          />
        )}
      </RadarChart>
    </Wrapper>
  )
}

const Wrapper = styled.div`

  user-select: none;

  .recharts-wrapper {
    width: 100% !important;
    height: auto !important;

    svg {
      max-width: 100%;
    }
  }

  .recharts-polar-grid-angle {
    display: none;
  }
  .recharts-polar-angle-axis-tick line {
    display: none;
  }
`
